// extracted by mini-css-extract-plugin
export var clarsyntHeader = "one-pager-module--clarsynt-header--3zU0N";
export var clarsyntBox = "one-pager-module--clarsynt-box--2l7BY";
export var clarsyntLogoFull = "one-pager-module--clarsynt-logo-full--2WCO-";
export var clarsyntGradient = "one-pager-module--clarsynt-gradient--1RVdo";
export var clarsyntWhenArtificial = "one-pager-module--clarsynt-when-artificial--3ye3V";
export var imageTextRow = "one-pager-module--image-text-row--lCh-c";
export var clarsyntWhiteRow = "one-pager-module--clarsynt-white-row--1AyVP";
export var imageColumn = "one-pager-module--image-column--1nBXt";
export var clarsyntOrangeImg = "one-pager-module--clarsynt-orange-img--3pelV";
export var textColumn = "one-pager-module--text-column--1ll74";
export var businessGrowthText = "one-pager-module--business-growth-text--3EfFm";
export var clarsyntText = "one-pager-module--clarsynt-text--Rtc4q";
export var clarsyntImaginingText = "one-pager-module--clarsynt-imagining-text--VvcwS";
export var clarsyntServicesRow = "one-pager-module--clarsynt-services-row--210Ks";
export var clarsyntServicesFlexbox = "one-pager-module--clarsynt-services-flexbox--A3rBr";
export var clarsyntServicesFlexboxUpper = "one-pager-module--clarsynt-services-flexbox-upper--2zpS4";
export var clarsyntServicesColumn = "one-pager-module--clarsynt-services-column--3T_uC";
export var contactUsText = "one-pager-module--contact-us-text--AjqJH";
export var clarsyntSubscriptionForm = "one-pager-module--clarsynt-subscription-form--1I29w";
export var clarsyntSubscriptionFormWrapper = "one-pager-module--clarsynt-subscription-form-wrapper--1gW_A";
export var aiInReachBox = "one-pager-module--ai-in-reach-box--2Ay7a";
export var aiInReachText = "one-pager-module--ai-in-reach-text--2fMDs";
export var contactUsForm = "one-pager-module--contact-us-form--1lTYe";
export var clarsyntLogoIcon = "one-pager-module--clarsynt-logo-icon--3vDhH";
export var clarsyntEngageText = "one-pager-module--clarsynt-engage-text--2A6PX";
export var clarsyntEngageImage = "one-pager-module--clarsynt-engage-image--UF3kd";
export var clarsyntContactFormWrapper = "one-pager-module--clarsynt-contact-form-wrapper--2khVH";
export var bgColorOffwhite = "one-pager-module--bg-color-offwhite--1fq7s";
export var allCategories = "one-pager-module--all-categories--1g4m3";
export var categoryHeaderIcon = "one-pager-module--category-header-icon--CwylN";
export var contactHeaderIcon = "one-pager-module--contact-header-icon--2l_Bc";
export var categoryTitle = "one-pager-module--category-title--2r4YU";
export var insightsSearch = "one-pager-module--insights-search--3-5zm";
export var prevNextPost = "one-pager-module--prev-next-post--14x6u";