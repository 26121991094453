import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";
import ContactForm from "../components/ContactForm";
import ContactPageForm from "../components/ContactPageForm";
import * as style from "../styles/one-pager.module.scss";
import extractCodeInjectionElements from "../utils/extractCodeInjectionElements";

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */

const Page = ({ data, location }) => {
    const page = data.ghostPage;
    const posts = data.allGhostPost.edges;

    let enableArticlesBlock = false;
    let enableContactBlock = false;
    let contactPage = false;
    let careersPage = false;

    const styleElements = extractCodeInjectionElements(
        `style`,
        ``.concat(page.codeinjection_head || ``, page.codeinjection_foot || ``)
    );

    const footScriptElements = extractCodeInjectionElements(
        `script`,
        page.codeinjection_foot || ``
    );

    const ContactPageHeader = () => (
        <div className={`${style.contactHeaderIcon}`}>
            <img src={page.tags[0].feature_image} />
        </div>
    );

    if (page.tags.length > 0) {
        let templateTags = page.tags.map((tag) => tag.name);

        enableArticlesBlock = templateTags.includes(`#enable_articles_block`)
            ? true
            : false;
        enableContactBlock = templateTags.includes(`#enable_contact_block`)
            ? true
            : false;
        contactPage = templateTags.includes(`#contact_page`) ? true : false;
        careersPage = templateTags.includes(`#careers_page`) ? true : false;
    }

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">
                    {`${styleElements.map((e) => e?.innerText).join(``)}`}
                </style>
            </Helmet>

            <Layout
                headerImage={page.feature_image}
                headerText={page.title}
                headerCategory={
                    contactPage || careersPage ? <ContactPageHeader /> : null
                }
            >
                <article
                    className={
                        contactPage || careersPage ? `contact-layout` : ``
                    }
                >
                    {/* The main page content */}
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{
                            __html: page.childHtmlRehype.html,
                        }}
                    />

                    {enableArticlesBlock && (
                        <div className="container ghost-container">
                            <div className="featured-articles">
                                <h1>Featured Articles</h1>
                                <div className="featured-articles__cards">
                                    <PostCard
                                        key={posts[0].node.id}
                                        post={posts[0].node}
                                    />
                                    <PostCard
                                        key={posts[1].node.id}
                                        post={posts[1].node}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {enableContactBlock && <ContactForm />}

                    {contactPage && (
                        <ContactPageForm
                            portalId="6139600"
                            formId="a9531cf3-9215-4ec4-b97f-c811224a00e3"
                        />
                    )}

                    {careersPage && (
                        <ContactPageForm
                            portalId="6139600"
                            formId="bf2c44f7-ec08-4a86-bce3-51fd89ede871"
                        />
                    )}
                </article>
                {footScriptElements.map((script, index) => (
                    <script
                        key={`ci_f_${index}`}
                        type="text/javascript"
                        src={script?.getAttribute(`src`) || undefined}
                        async={script?.hasAttribute(`async`)}
                    >{`${script?.innerText}`}</script>
                ))}
            </Layout>
        </>
    );
};

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            codeinjection_head: PropTypes.string,
            codeinjection_foot: PropTypes.string,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            childHtmlRehype: PropTypes.object,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    feature_image: PropTypes.string,
                    length: PropTypes.number,
                    map: PropTypes.func,
                })
            ),
        }).isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Page;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
            childHtmlRehype {
                html
            }
        }

        allGhostPost(
            sort: { fields: published_at, order: DESC }
            filter: { featured: { eq: true }, slug: { ne: "data-schema" } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
