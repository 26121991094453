import React from "react";
import HubspotForm from "react-hubspot-form";
import PropTypes from "prop-types";

const ContactPageForm = ({ formId, portalId }) => (
    <div className="contact-page-form">
        <HubspotForm
            portalId={portalId}
            formId={formId}
            loading={<div>Loading...</div>}
        />
    </div>
);

ContactPageForm.propTypes = {
    formId: PropTypes.string.isRequired,
    portalId: PropTypes.string.isRequired,
};

export default ContactPageForm;
